import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';
import $ from 'jquery';
export class Offence{
  offence: string;
  penalty: number;
  id: number;
  dzoTrnx: any;
  offenceTrnx: any;
}
@Component({
  selector: 'app-offencemaster',
  templateUrl: './offencemaster.component.html',
  styleUrls: ['./offencemaster.component.css']
})
export class OffencemasterComponent implements OnInit {

  offenceList: any;
  offence: string;
  penalty: number;
  dzongkhag: any = 0;
  id: number;
  offencemaster: Offence;
  dzongkhagList: any;

  @ViewChild('myModal', { static: false }) myModal: Component
  modalRef: any;
  @ViewChild('editModal', { static: false }) editModal: Component
  editmodalRef: any;
  @ViewChild('offenceMappingModal', { static: false }) offenceMappingModal: Component
  mappingmodalRef: any;

  constructor(private apiService: ApiService, private router: Router, 
    private modalService: ModalManager) { 
    this.offencemaster = new Offence();
  }

  ngOnInit() {
    localStorage.setItem("login", "true");
    this.apiService.getAllOffenceMaster().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.offenceList = result;
      }else{
      }
     
    });

    this.apiService.getAllDzongkhag().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.dzongkhagList = result;
      }else{
      }
     
    });
  }

  addOffence(){
    $("#mymodal").removeClass("fade");
    this.modalRef = this.modalService.open(this.myModal, {
      size: "md",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop",
      fade:false
    })
  }

  addOffenceMapping(){
    this.mappingmodalRef = this.modalService.open(this.offenceMappingModal, {
      size: "md",
      modalClass: 'offenceMappingModal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  add(){
    this.offencemaster.offence = this.offence;
    this.offencemaster.penalty = this.penalty;
    //this.offencemaster.dzoTrnx = this.dzongkhag;

    this.apiService.submitOffenceMaster(this.offencemaster).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);
      
      if (result != null){
        alert("Offence added")
        this.modalService.close(this.myModal);
        this.back();
      }else{
      }
     
    });
  }

  addMapping(){
    this.offencemaster.offenceTrnx = this.offence;
    this.offencemaster.dzoTrnx = this.dzongkhag;

    this.apiService.submitOffenceMapping(this.offencemaster).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        alert("Offence mapping added")
        this.modalService.close(this.offenceMappingModal);
        this.back();
      }else{
      }
     
    });
  }

  update(){
    // let dzoArray = [];
    // dzoArray.push(this.dzongkhag);
    this.offencemaster.offence = this.offence;
    this.offencemaster.penalty = this.penalty;
    //this.offencemaster.dzoTrnx = dzoArray;
    debugger
    this.offencemaster.id = this.id;
    this.apiService.submitOffenceMaster(this.offencemaster).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);
      
      if (result != null){
        alert("Offence updated")
        this.modalService.close(this.editModal);
        this.back();
      }else{
      }
     
    });
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['offencemaster']));
  }

  editOffenceModal(id){
    for(var i =0; i<this.offenceList.length; i++){
      debugger
      if(id == this.offenceList[i].id){
        this.offence = this.offenceList[i].offence;
        this.penalty = this.offenceList[i].penalty;
        //this.dzongkhag = this.offenceList[i].dzongkhag.id; 
        this.id = id;

      }
    }
    this.editmodalRef = this.modalService.open(this.editModal, {
      size: "md",
      modalClass: 'editmodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  editOffence(id){
    this.offencemaster.offence = this.offence;
    this.offencemaster.penalty = this.penalty;
    this.offencemaster.id = id;
    this.offencemaster.dzoTrnx = this.dzongkhag;
    this.apiService.submitOffenceMaster(this.offencemaster).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);
      
      if (result != null){
        alert("Offence updated")
        this.modalService.close(this.myModal);
        this.back();
      }else{
      }
     
    });
  }

  delete(id){
    this.offencemaster.id = id;
    if(confirm("Are you sure to delete ")) {
      this.apiService.deleteOffenceMaster(this.offencemaster).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        //console.log(result);
        
        //if (result != null){
          alert("Offence deleted")
          this.modalService.close(this.myModal);
          this.back();
        //}else{
        //}
      
      });
    }
  }

}
