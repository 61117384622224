import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-offenceview',
  templateUrl: './offenceview.component.html',
  styleUrls: ['./offenceview.component.css']
})
export class OffenceviewComponent implements OnInit {

  @ViewChild('myModal', { static: false }) myModal: Component

  offenceList: any;
  file: any;
  modalRef: any;

  constructor(private apiService: ApiService, private modalService: ModalManager, private router: Router) { }

  ngOnInit() {

    localStorage.setItem("login", "true");
    this.apiService.getAllOffences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null){
       this.offenceList = result;
      }else{
      }
     
    });

  }

  loadImage(img){
    this.file = img;
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['rbp']));
  }

}
