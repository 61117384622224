import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';


export class Register{
  cid: string;
  dob: string;
  email: string;
  mobileNo: number;
  role: number;
  createdOn: Date;
  pinCode: number;
  id: number;

}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

 

  userList: any;
  viewFlag: boolean = false;
  flagList: boolean = false;
  oneUser: any;

  cidNo: string;
  dob: string;
  email: string;
  mobileNo: number;
  role: string;
  createdOn: Date;
  pinCode: number;
  rowId: number;
  registerObj: Register;
  offenceList: any;
  userCount: any;
  incidenceCount: any;
  newUserCount: any = 0;
  ndiFlag: any;
  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //const name = params['name'];
      const cid = params['cid'];
      //const dob = params['dob'];
      this.cidNo = cid;
      console.log("cid is ", this.cidNo); // For debugging purposes
    });
    if(this.cidNo != '' || this.cidNo != undefined){
      localStorage.setItem("login", "true")
      //get cid from ndi
      
      // this.apiService.getRoleWithCID(this.cidNo).subscribe((response) => {
      //   var result = JSON.parse(JSON.stringify(response));
      //   localStorage.setItem('userrole', result.role)
      //   if (result != null) {
      //     if (result.role === 1) {
      //       // role id 1 is admin
      //       //this.router.navigateByUrl("/dashboard", {skipLocationChange: true});
      //     }
      //     else if (result.role === 2) {
      //       //role id 2 is enforcer
      //       localStorage.setItem("enforceruid", this.cidNo)
      //       //this.router.navigateByUrl("/dashboard", {skipLocationChange: true});
      //     }
          
      //   } else {
      //     alert("Username or Password is incorrect. ");
      //   }
      // },
      // (error) => {
      //   alert("Login failed, Please try again later.")
      // });
    }
    let loginFlag = localStorage.getItem("login");
    if(loginFlag == 'true'){
      localStorage.setItem("login","true")
      this.apiService.getAllOffences().subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        
        if (result != null){
          let count = 0;
         for(var i=0; i<=result.length; i++){
          count = i;
         }
         this.offenceList = count;
        }else{
        }
       
      });
  
      this.getUsers();
      this.getIncidence();
    }
    else{
      alert("Please login. ")
      this.router.navigateByUrl("/login", {skipLocationChange: true});
    }
    
  }

  getUsers(){
    this.apiService.getUsers().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        let count = 0;
       for(var i=0; i<result.length; i++){
        count = i;
        
        if(result[i].pinCode==0){
          this.newUserCount ++;
        }
       }
       this.userCount = count;
      }else{
      }
     
    });
  }

  getIncidence(){
    this.apiService.getIncidences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        let count = 0;
       for(var i=0; i<=result.length; i++){
        count = i;
       }
       this.incidenceCount = count;
      }else{
      }
     
    });
  }

}
