import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-offencemanagement',
  templateUrl: './offencemanagement.component.html',
  styleUrls: ['./offencemanagement.component.css']
})
export class OffencemanagementComponent implements OnInit {

  offenceList: any;

  constructor(private apiService: ApiService, private router: Router)
  { }

  ngOnInit() {
    this.apiService.getAllOffenceMaster().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      debugger;
      
      if (result != null){
       this.offenceList = result;
      }else{
      }
     
    });
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['enforcer']));
  }

}
