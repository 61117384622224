import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  userid: string;
  oldPassword: string;
  password: string;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.oldPassword = '';
    this.password = '';
    this.userid = localStorage.getItem('enforceruid')
  }

  update(){
    console.log(this.userid);
    
    if(this.oldPassword == ''){
      alert("Current password is required")
    }
    else if(this.password == ''){
      alert("New password is required")
    }
    else{
      this.apiService.changePIN(this.userid, this.oldPassword, this.password).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        
        if (result != null) {
          if(result.pinCode == this.password){
            alert("Password Updated")
            this.ngOnInit();
          }else {
            alert("Password could not be updated. ");
          }
        } else {
          alert("Password could not be updated. ");
        }
  
      });
    }
  }

}
