import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';

export class About {
  about: string;
  id: number
}
@Component({
  selector: 'app-aboutusmaster',
  templateUrl: './aboutusmaster.component.html',
  styleUrls: ['./aboutusmaster.component.css']
})
export class AboutusmasterComponent implements OnInit {

  @ViewChild('myModal', { static: false }) myModal: Component
  modalRef: any;
  @ViewChild('editModal', { static: false }) editmodal: Component
  editmodalRef: any;

  aboutusList: any;
  aboutClass: About; 

  about: any;
  id: any;


  constructor(private apiService: ApiService,
    private modalService: ModalManager, private router: Router) { }

  ngOnInit() {
    this.aboutClass = new About();
    localStorage.setItem("login", "true");
    this.apiService.getAllAboutUsMaster().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.aboutusList = result;
      }else{
      }
     
    });
  }

  addOpenModal(){
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  add(){
    this.aboutClass.about = this.about;
    this.apiService.addAboutUsMaster(this.aboutClass).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null){
        alert("Success")
        this.modalService.close(this.myModal);
        this.back();
      }else{
      }
     
    });
  }

  editAboutModal(id){
    debugger
    for(var i =0; i<this.aboutusList.length; i++){
      
      if(id == this.aboutusList[i].id){
        this.about = this.aboutusList[i].about;
        this.id = id;

      }
    }
    this.editmodalRef = this.modalService.open(this.editmodal, {
      size: "lg",
      modalClass: 'editmodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  update(){
   this.aboutClass.about = this.about;
   this.aboutClass.id = this.id;
    this.apiService.addAboutUsMaster(this.aboutClass).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        alert("Updated")
        this.modalService.close(this.editmodal);
        this.back();
      }else{
      }
     
    });
  }

  delete(id){
    this.aboutClass.id = id;
    if(confirm("Are you sure to delete ")) {
      this.apiService.deleteAboutUsMaster(this.aboutClass).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
          alert("About Deleted")
          this.modalService.close(this.myModal);
          this.back();
      });
    }
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['dashboard']));
  }

}
