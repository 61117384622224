import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-incidence',
  templateUrl: './incidence.component.html',
  styleUrls: ['./incidence.component.css']
})
export class IncidenceComponent implements OnInit {

  @ViewChild('myModal', { static: false }) myModal: Component

  initialFlag: boolean = true;
  reportFlag: boolean = false;
  incidenceList: any;
  createdOn: any = 'null';
  toDate: string;
  fromDateValid: boolean = false;
  toDateValid: boolean = false;
  modalRef: any;
  image: any;
  
  constructor(private apiService: ApiService, private router: Router, private modalService: ModalManager) { }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.initialFlag = true;
    this.reportFlag = false;
  }
  
  generateReport(){
   
      this.apiService.getIncidenceReport(this.createdOn).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        if (result != null){
        this.incidenceList = result;
        this.initialFlag = false;
        this.reportFlag = true;
        }else{
        }
      
      });
    
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['incidence']));
  }

  loadImage(img){
    this.image = img;
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  notify(id){
    
  }

}
