import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';

export class ContactDTO {
  content: string;
  id: number;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  codeList: any;
  @ViewChild('myModal', { static: false }) myModal: Component
  @ViewChild('viewModal', { static: false }) viewModal: Component
  modalRef: any;
  content: any;
  codeDto : ContactDTO;
  id: any;

  constructor(private apiService: ApiService, 
    private modalService: ModalManager,
    private router: Router) { }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.codeDto = new ContactDTO()
    this.apiService.getContact().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));

      if (result != null) {
        this.codeList = result;
      } else {
      }

    });
  }

  edit(id) {
    this.content = this.codeList[0].content;
    this.id = this.codeList[0].id
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })

  }

  update() {
    
    this.codeDto.content = this.content;
    this.codeDto.id = this.id
    
    this.apiService.submitContact(this.codeDto).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);

      if (result != null) {
        alert("Contact updated")
        this.modalService.close(this.myModal);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['contact']));
      } else {
      }

    });
  }


}
