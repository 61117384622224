import { Component, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as internal from 'assert';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';
import { News } from './add/add.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newsList: any;
  //@ViewChild('myModal') myModal;
  @ViewChild('myModal', { static: false }) myModal: Component
  @ViewChild('viewModal', { static: false }) viewModal: Component
  modalRef: any;
  title: string;
  details: string;
  image: string;
  type: string;
  createdOn: string;
  newsObj: News;
  id: number; 
  fileUrl: any;

  constructor(private apiService: ApiService, 
    private modalService: ModalManager,
    private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.newsObj = new News();
    this.apiService.getAllNews().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));

      if (result != null) {
        this.newsList = result;
      } else {
      }

    });
  }
  

  edit(id) {
    this.apiService.getNewsById(id).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);

      if (result != null) {
        this.title = result[0].title;
        this.details = result[0].details;
        this.id = result[0].id;

        this.modalRef = this.modalService.open(this.myModal, {
          size: "md",
          modalClass: 'mymodal',
          hideCloseButton: false,
          centered: false,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true,
          backdropClass: "modal-backdrop"
        })
      } else {

      }

    });

  }

  update() {
    debugger
    this.newsObj.title = this.title;
    this.newsObj.details = this.details;
    this.newsObj.type = 'news';
    this.newsObj.id = this.id;
    this.newsObj.image = this.image;
    this.apiService.submitNews(this.newsObj).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);

      if (result != null) {
        alert("News updated")
        this.modalService.close(this.myModal);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['news']));
      } else {
      }

    });
  }

  delete(id){
    if(confirm("Are you sure to delete ")) {
      this.apiService.deleteNews(id).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        //console.log(result);
  
        if (result != null) {
          alert("News deleted")
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['news']));
        } else {
        }
  
      });
    }
    
  }


  fileChangeEvent(fileInput: any) {
    // this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      //const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;
      
      
      if (fileInput.target.files[0].size > max_size) {
        // this.imageError =
        alert('Maximum size allowed is ' + max_size / 1000 + 'Mb');
        return false;
      }

      // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
      //     // this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const imgBase64Path = e.target.result;
        this.image = imgBase64Path;
        console.log(this.image);


        // const image = new Image();
        // image.src = e.target.result;
        // image.onload = rs => {
        //   const img_height = rs.currentTarget['height'];
        //   const img_width = rs.currentTarget['width'];

        //   console.log(img_height, img_width);


        //   if (img_height > max_height && img_width > max_width) {
        //     // this.imageError =
        //     //     'Maximum dimentions allowed ' +
        //     //     max_height +
        //     //     '*' +
        //     //     max_width +
        //     //     'px';
        //     return false;
        //   } else {
        //     const imgBase64Path = e.target.result;
        //     this.image = imgBase64Path;
        //     console.log(this.image);

        //     // this.isImageSaved = true;
        //     // this.previewImagePath = imgBase64Path;
        //   }
        // };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  loadImage(img){
    this.fileUrl = img;
    this.modalRef = this.modalService.open(this.viewModal, {
      size: "lg",
      modalClass: 'viewmodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

}
