import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  newsList: any;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getAllEvents().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        this.newsList = result;
      }else{
      }
     
    });
  }

}
