import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-enforcer',
  templateUrl: './enforcer.component.html',
  styleUrls: ['./enforcer.component.css']
})
export class EnforcerComponent implements OnInit {

  dzongkhagId: any;
  offenceMasterCount: any;
  offenceCount: any;
  userList: any;
  incidenceCount: any;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    localStorage.setItem("login","true");
    this.dzongkhagId = localStorage.getItem("dzongkhagId")

    this.apiService.getAllOffenceMasterByDzongkhag(this.dzongkhagId).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        let count = 0;
       for(var i=0; i<=result.length; i++){
        count = i;
       }
       this.offenceMasterCount = count;
      }else{
      }
     
    });

    this.getOffenceCount();
    this.getIncidenceCount();
    this.getUsersForSubAdmin();
  }

  getOffenceCount(){
    this.apiService.getAllOffences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        let count = 0;
       for(var i=0; i < result.length; i++){
        if(result[i].dzongkhagId === parseInt(this.dzongkhagId)){
          count += 1;
        }
         
       }
       this.offenceCount = count;
      }else{
      }
     
    });
  }

  getIncidenceCount(){
    this.apiService.getIncidences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        let count = 0;
       for(var i=0; i < result.length; i++){
        if(result[i].dzongkhagId === parseInt(this.dzongkhagId)){
          count += 1;
        }
         
       }
       this.incidenceCount = count;
      }else{
      }
     
    });
  }

  getUsersForSubAdmin(){
    var userId = localStorage.getItem("enforceruid")
      this.apiService.getUsers().subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        
        if (result != null){
          let count = 0;
         for(var i=0; i < result.length; i++){
          if(result[i].dzongkhagId.id === parseInt(this.dzongkhagId)){
            count += 1;
          }
           
         }
         this.userList = count;
        }else{
        }
       
      });
  }

}
