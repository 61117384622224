import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {

  isCollapsed = false;

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }

  
  loginFlag: any;
  login: boolean = false;
  constructor() { }

  ngOnInit() {
    
    this.loginFlag= localStorage.getItem("login");
    if(this.loginFlag == 'false'){
      this.login = false;
    }
    else{
      this.login = true;
    }
  }

}
