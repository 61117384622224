import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

export class Register{
  cid: string;
  dob: string;
  email: string;
  mobileNo: number;
  role: number;
  createdOn: Date;
  pinCode: number;
  name: string;
  dzongkhagId: any;
  bankName: string;
  accountNo: number;
}

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

  cidNo: string;
  dob: string;
  email: string;
  mobileNo: number;
  role: number;
  createdOn: Date;
  pinCode: number;
  registerObj: Register;
  name: string;
  cidError: boolean = false;
  nameError: boolean = false;
  dobError: boolean = false;
  mobileNoError: boolean = false;
  roleError: boolean = false;
  dzongkhagError: boolean = false;
  dzongkhagList: any;
  dzongkhag: any = 0;
  bankname: string = "0";
  accountNo: number;
  bankList = [
    {
      "name": "Bank of Bhutan",
      "id": "1",
    },
    {
      "name": "Bhutan National Bank",
      "id": "2",
    },
    {
      "name": "Bhutan Development Bank",
      "id": "3",
    },
    {
      "name": "Druk Punjab National Bank",
      "id": "4",
    },
    {
      "name": "Tashi Bank",
      "id": "5",
    }
  ]


  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.role = 0;
    this.registerObj = new Register();
    this.getDzongkhag();
  }

  getDzongkhag() {
    this.apiService.getAllDzongkhag().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));

      if (result != null) {
        this.dzongkhagList = response;
      } 

    })
    
  }

  getName(){
    this.apiService.getNameFromAPI(this.cidNo).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      console.log(result);
      
      if (result != null){
        this.name = response.citizenDetailsResponse.citizenDetail[0].firstName +
                    " " + response.citizenDetailsResponse.citizenDetail[0].middleName +
                    " " + response.citizenDetailsResponse.citizenDetail[0].lastName ;
        var dateData = response.citizenDetailsResponse.citizenDetail[0].dob.split('/');
        debugger
        var day = dateData [0];
        var month = dateData [1];
        var year = dateData [2];
        var dateFormated = year + "-" + month + "-" + day;
        this.dob = dateFormated;
        this.mobileNo = response.citizenDetailsResponse.citizenDetail[0].mobileNumber;
      }else{
        //this.presentError();
      }
     
    })
  }

  addUser(){
    
    if(this.cidNo == '' || this.cidNo == undefined){
      this.cidError = true;
      return
    }
    if(this.name == '' || this.name == undefined){
      this.nameError = true;
      return
    }
    if(this.dob == '' || this.dob == undefined){
      this.dobError = true;
      return
    }
    if(this.mobileNo == 0 || this.mobileNo == undefined){
      this.mobileNoError = true;
      return
    }
    if(this.role == 0 || this.role == undefined){
      this.roleError = true;
      return
    }
    if(this.dzongkhag == '' || this.dzongkhag == undefined){
      this.dzongkhagError = true;
      return
    }
    this.registerObj.cid = this.cidNo;
    this.registerObj.dob = this.dob;
    this.registerObj.email = this.email;
    this.registerObj.mobileNo = this.mobileNo;
    this.registerObj.role = this.role;
    this.registerObj.createdOn = null;
    this.registerObj.pinCode = 0;
    this.registerObj.name = this.name;
    this.registerObj.dzongkhagId = this.dzongkhag;
    this.registerObj.bankName = this.bankname;
    this.registerObj.accountNo = this.accountNo;

    this.apiService.approveUser(this.registerObj).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        if(result.status == "Exists"){
          this.presentAlert("User already exists.");
        }else{
          this.presentAlert("Registration successful. PIN Code has been sent to the user. ");
        }
        
      }else{
        this.presentError();
      }
     
    })
  }

  alerts: any;
  presentAlert(msg) {
    //this.alerts = msg;
    alert(msg);
    this.router.navigateByUrl('/userlist')
  }


  presentError() {
    this.alerts = "Registration failed "
  }

}
