import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

export class News{
  title: string;
  details: string;
  image: string;
  type: string;
  createdOn: string;
}

@Component({
  selector: 'app-addevent',
  templateUrl: './addevent.component.html',
  styleUrls: ['./addevent.component.css']
})
export class AddeventComponent implements OnInit {
  
  title: string;
  details: string;
  image: string;
  type: string;
  createdOn: string;
  newsObj: News;
  myFiles: File[] = [];

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { 
    this.newsObj = new News();
  }

  ngOnInit() {
  }

  addNews(){
    this.newsObj.title = this.title;
    this.newsObj.details = this.details;
    this.newsObj.type = 'events';
    this.apiService.submitNews(this.newsObj).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);
      
      if (result != null){
        alert("Event added")
        this.router.navigateByUrl('events')
      }else{
      }
     
    });
  }

  selectFile(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

}
