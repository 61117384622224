import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

export class Offence{
  offenceType: string;
  cidNo: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  mobileNo: string;
  schoolName: string;
  studentPhoneNo: string;
  guardian: string;
  licenseNo: string;
  vehicleNo: string;
  passportNo: string;
  location: string;
  file: string;
  offences: string;
  penalty: any;
  nationality: string;
  createdOn: string;
  paymentStatus: string;
  dzongkhagId: any;
  token: number;
  toDate: string;
  role: string;
}


@Component({
  selector: 'app-incentivereport',
  templateUrl: './incentivereport.component.html',
  styleUrls: ['./incentivereport.component.css']
})
export class IncentivereportComponent implements OnInit {

  offenceList: any;
  offenceObj: Offence;

  constructor(private apiService: ApiService) { 
    this.offenceObj = new Offence();
  }

  ngOnInit() {

    localStorage.setItem("login", "true")
    this.apiService.getAllIncentiveReport().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      console.log(result)
      
      if (result != null){
       this.offenceList = result;
      }
     
    });
  }

  // Define a method to map bankName to a readable name
  getBankName(bankName: number): string {
    const bankNames = {
      1: 'Bank of Bhutan',
      2: 'Bhutan National Bank',
      3: ' Bhutan Development Bank',
      4: 'Druk Punjab National Bank',
      5: 'Tashi Bank'
    };
    
    return bankNames[bankName] || 'Unknown Bank'; // Default to 'Unknown Bank' if the value is not found
  }

  

  // Define a method to map bankName to a readable name
  getRoleName(roleId: number): string {
    const roleNames = {
      1: 'Admin',
      2: 'Enforcer',
      3: 'Public',
      4: 'Enforcer Secondary Admin',
      5: 'RBP'
    };
    
    return roleNames[roleId] || 'Unknown Role'; // Default to 'Unknown Bank' if the value is not found
  }

  

}
