import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Register } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  cidNo: string;
  pinCode: number;
  registerObj: Register;
  ndiFlagY: boolean = true;
  ndiFlagN: boolean = false;

  constructor(private router: Router, private apiService: ApiService) { 
    localStorage.setItem("login", "false");
    this.registerObj = new Register();
  }

  ngOnInit() {
    
  }

  // ndi login added on June 2nd 2024
  loginRedirect(obj){
    if(obj == 'ndi'){
      window.location.href = 'http://128.199.179.198:81?type=weblogin';
    }
    else{
      this.ndiFlagN = true;
      this.ndiFlagY = false;
    }
  }

  login(){
    localStorage.setItem("login","true");
    localStorage.setItem("enforceruid", this.cidNo)
    
    //this.router.navigateByUrl("/enforcer", {skipLocationChange: true});
    this.registerObj.cid = this.cidNo;
    this.registerObj.pinCode = this.pinCode;
    if(this.registerObj.cid == undefined || this.registerObj.cid == ''){
      alert("User name is required")
    }
    else if (this.registerObj.pinCode == undefined){
      alert("Password is required")
    }
    else{
      this.apiService.loginValidate(this.registerObj).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        localStorage.setItem('userrole', result.role)
        if (result != null) {
          if (result.role === 1) {
            // role id 1 is admin
            this.router.navigateByUrl("/dashboard", {skipLocationChange: true});
          }
          else if (result.role === 2) {
            //role id 2 is enforcer
            localStorage.setItem("enforceruid", this.cidNo)
            this.router.navigateByUrl("/dashboard", {skipLocationChange: true});
          }
          else if (result.role === 3){
            //role id 3 is public and web login is not allowed
            // let navExtras: NavigationExtras = {
            //   queryParams: {
            //       cid: this.cidNo,
            //   }
            // };
            // this.router.navigateByUrl("/dashboard", {skipLocationChange: true});
            alert("You are not authorised to login.");
          }
          else if (result.role === 4){
            //role id 4 is enforcer secondary admin
            localStorage.setItem("enforceruid", this.cidNo);
            localStorage.setItem("dzongkhagId", result.dzongkhagId.id)
            this.router.navigateByUrl("/enforcer", {skipLocationChange: true});
          }
          else if (result.role === 5){
            // otherwise role id is 5 which is for rbp user
            localStorage.setItem("rbpuid", this.cidNo)
            this.router.navigateByUrl("/rbp", {skipLocationChange: true});
          }
        } else {
          alert("Username or Password is incorrect. ");
        }
  
      },
      (error) => {
        alert("Login failed, Please try again later.")
     });
    }
    
    
  }

  // async presentError() {
  //   const alert = await this.alertController.create({
  //     cssClass: 'my-custom-class',
  //     header: 'Failure',
  //     subHeader: 'Login Failed',
  //     message: 'Please try again',
  //     buttons: ['OK']
  //   });

  //   await alert.present();

  //   const { role } = await alert.onDidDismiss();
  //   console.log('onDidDismiss resolved with role', role);
  // }

}
