import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-incidencegallery',
  templateUrl: './incidencegallery.component.html',
  styleUrls: ['./incidencegallery.component.css']
})
export class IncidencegalleryComponent implements OnInit {

  incidenceImageList: any;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.apiService.getIncidences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        debugger
       this.incidenceImageList = result;
      }else{
        //error
      }
     
    });
  }

}
