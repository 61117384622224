import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';

export class Offence{
  offenceid: any;
  dzongkhag: any;
  dzoTrnx: any;
  offenceTrnx: any;
  id: any;
}


@Component({
  selector: 'app-offencemastermapping',
  templateUrl: './offencemastermapping.component.html',
  styleUrls: ['./offencemastermapping.component.css']
})


export class OffencemastermappingComponent implements OnInit {

  @ViewChild('offenceMappingModal', { static: false }) offenceMappingModal: Component
  mappingmodalRef: any;
  @ViewChild('editModal', { static: false }) editModal: Component
  editmodalRef: any;

  offenceList: any;
  offence: string;
  dzongkhag: any = 0;
  dzongkhagList: any;
  offencemaster: Offence;
  offenceMappingList: any;
  id: number;

  constructor( private apiService: ApiService,  
    private modalService: ModalManager,
    private router: Router) { }

  ngOnInit() {

    this.offencemaster = new Offence();

    this.apiService.getAllOffenceMasterMapping().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.offenceMappingList = result;
       
      }else{

      }
     
    });

    this.apiService.getAllOffenceMaster().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.offenceList = result;
      }else{
      }
     
    });

    this.apiService.getAllDzongkhag().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.dzongkhagList = result;
      }else{
      }
     
    });
  }

  addOffence() {
    this.mappingmodalRef = this.modalService.open(this.offenceMappingModal, {
      size: "md",
      modalClass: 'offenceMappingModal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  addMapping(){
    this.offencemaster.offenceTrnx = this.offence;
    this.offencemaster.dzoTrnx = this.dzongkhag;

    this.apiService.submitOffenceMapping(this.offencemaster).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        alert("Offence mapping added")
        this.modalService.close(this.offenceMappingModal);
        this.back();
      }else{
      }
     
    });
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['offencedzongkhagmapping']));
  }

  editOffenceModal(id){
    for(var i =0; i<this.offenceMappingList.length; i++){
      
      if(id == this.offenceMappingList[i].id){
        this.offence = this.offenceMappingList[i].offenceid.id;
        this.dzongkhag = this.offenceMappingList[i].dzongkhag.id; 
        this.id = id;
      }
    }
    this.editmodalRef = this.modalService.open(this.editModal, {
      size: "md",
      modalClass: 'editmodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  update(){
    let offenceArray = [];
    let dzoArray = [];
    offenceArray.push(this.offence);
    dzoArray.push(parseInt(this.dzongkhag));
    
    this.offencemaster.offenceTrnx = offenceArray;
    this.offencemaster.dzoTrnx = dzoArray;
    this.offencemaster.id = this.id;
    debugger
    this.apiService.updateOffenceMapping(this.offencemaster).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        alert("Offence mapping updated")
        this.modalService.close(this.editModal);
        this.back();
      }else{
      }
     
    });
  }

  delete(id){
    this.offencemaster.dzoTrnx = id;
    if(confirm("Are you sure to delete ")) {
      this.apiService.deleteOffenceMapping(this.offencemaster).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
         alert("Offence deleted")
         this.back();
      
      });
    }
  }

  
  checkBoxId: any = [];

  deleteSelectedOffence(){
    this.delete(this.checkBoxId)
  }

  isCheckedMap = {};

  check($event, i) {
    this.isCheckedMap[i] = !this.isCheckedMap[i]; 
    
    this.checkBoxId.push($event);
    console.log(this.checkBoxId);
  }

  

}
