import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  userid: string = '';
  mobileNo: string = '';
  oldPassword: string = '';
  password: string = '';

  constructor( private apiService: ApiService) { }

  ngOnInit() {
    this.userid = '';
    this.mobileNo = '';
    this.oldPassword = '';
    this.password = '';
  }

  update(){
      if(this.userid == ''){
        alert("User Id is required")
      }
      else if(this.mobileNo == ''){
        alert("Mobile number is required")
      }
      else if(this.oldPassword == ''){
        alert("Current password is required")
      }
      else if(this.password == ''){
        alert("New password is required")
      }
      else{
        this.apiService.changePIN(this.userid, this.oldPassword, this.password).subscribe((response) => {
          var result = JSON.parse(JSON.stringify(response));
          
          if (result != null) {
            if(result.pinCode == this.password){
              alert("Password Updated")
              this.ngOnInit();
            }else {
              alert("Password could not be updated.");
            }
          } else {
            alert("Password could not be updated. ");
          }
    
        });
      }
  }

}
