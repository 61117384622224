import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-rbp',
  templateUrl: './rbp.component.html',
  styleUrls: ['./rbp.component.css']
})
export class RbpComponent implements OnInit {

  cidNo: number;
  offenceList: any;
  viewFlag: boolean = false;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  search(){
    this.apiService.getOffenceByCid(this.cidNo).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null){
       this.offenceList = result;
       this.viewFlag = true;
      }
    });
  }

}
