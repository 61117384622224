import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalManager } from "ngb-modal";
import { Subject } from "rxjs";
import { ApiService } from "../api.service";
import { Router } from "@angular/router";

export class Register {
  cid: string;
  dob: string;
  email: string;
  mobileNo: number;
  role: number;
  createdOn: Date;
  pinCode: number;
  name: string;
  id;
  number;
  dzongkhagId: any;
  status: string;
  accountNo: number;
  bankName: string;
}

@Component({
  selector: "app-userlist",
  templateUrl: "./userlist.component.html",
  styleUrls: ["./userlist.component.css"],
})
export class UserlistComponent implements OnInit {
  @ViewChild("myModal", { static: false }) myModal: Component;
  modalRef: any;
  userList: any;

  viewFlag: boolean = false;
  flagList: boolean = false;
  oneUser: any;

  cidNo: string;
  dob: string;
  email: string;
  mobileNo: number;
  role: any = 0;
  createdOn: Date;
  pinCode: number;
  rowId: number;
  name: string;
  registerObj: Register;
  sortedUserList: any = [];
  viewUserList: any;
  dzongkhagList: any;
  dzongkhag: any = 0;
  dzongkhagError: boolean = false;
  bankname: string = "0";
  accountNo: number;
 
  //dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  bankList = [
    {
      "name": "Bank of Bhutan",
      "id": "1",
    },
    {
      "name": "Bhutan National Bank",
      "id": "2",
    },
    {
      "name": "Bhutan Development Bank",
      "id": "3",
    },
    {
      "name": "Druk Punjab National Bank",
      "id": "4",
    },
    {
      "name": "Tashi Bank",
      "id": "5",
    }
  ]

  constructor(
    private apiService: ApiService,
    private router: Router,
    private modalService: ModalManager
  ) {}

  ngOnInit() {
    var userRole = localStorage.getItem("userrole")

    this.registerObj = new Register();
    localStorage.setItem("login", "true");
    this.getDzongkhag();

    this.apiService.getUsers().subscribe((response) => {
      debugger
      var result = JSON.parse(JSON.stringify(response));
      if (result != null) {
        if(userRole === "1"){
          this.userList = result;
          this.viewUserList = this.userList;
        }else{
          //this is for secondary admin
          var dzongkhagId = localStorage.getItem("dzongkhagId")

          for(var i=0; i < result.length; i++){
            if(result[i].dzongkhagId.id === parseInt(dzongkhagId)){
              this.userList.push(result[i])
            }
          }
        }
        
        this.dtTrigger.next();
      } else {
        // this.presentError();
      }
    });

    this.viewFlag = false;
    this.flagList = true;
  }

  sort() {
    this.sortedUserList = [];
    for (var i = 0; i < this.userList.length; i++) {
      if (this.role == this.userList[i].role) {
        this.sortedUserList.push(this.userList[i]);
      }
    }
    this.viewUserList = this.sortedUserList;

    this.viewFlag = false;
    this.flagList = true;
  }

  sortByDzongkhag(){
    this.sortedUserList = [];
    debugger
    for (var i = 0; i < this.userList.length; i++) {
      if (this.dzongkhag == this.userList[i].dzongkhagId.id) {
        this.sortedUserList.push(this.userList[i]);
      }
    }
    this.viewUserList = this.sortedUserList;

    this.viewFlag = false;
    this.flagList = true;
  }

  getUsers(id) {
    //console.log(cid);
    // this.viewFlag = true;
    // this.flagList = false;
    this.getDzongkhag();

    for (var i = 0; i < this.userList.length; i++) {
      if (this.userList[i].id == id) {
        this.cidNo = this.userList[i].cid;
        this.name = this.userList[i].name;
        this.dob = this.userList[i].dob;
        this.email = this.userList[i].email;
        this.mobileNo = this.userList[i].mobileNo;
        this.role = this.userList[i].role;
        this.rowId = this.userList[i].id;
        this.dzongkhag = this.userList[i].id;
        this.accountNo = this.userList[i].accountNo;
        this.bankname = this.userList[i].bankName;
        
        this.modalRef = this.modalService.open(this.myModal, {
          size: "lg",
          modalClass: "mymodal",
          hideCloseButton: false,
          centered: false,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true,
          backdropClass: "modal-backdrop",
        });
      }
    }
  }

  getDzongkhag() {
    this.apiService.getAllDzongkhag().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null) {
        this.dzongkhagList = response;
      }
    });
  }

  delUsers(id) {
    if (confirm("Are you sure to delete ")) {
      this.apiService.deleteUsers(id).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["userlist"]));
        if (result != null) {
          alert("User Deleted");
        } else {
        }
      });
    }
  }

  updateUser(id) {
    this.registerObj.cid = this.cidNo;
    this.registerObj.dob = this.dob;
    this.registerObj.email = this.email;
    this.registerObj.mobileNo = this.mobileNo;
    this.registerObj.role = this.role;
    this.registerObj.createdOn = null;
    //this.registerObj.pinCode = 0;
    this.registerObj.name = this.name;
    this.registerObj.id = id;
    this.registerObj.dzongkhagId = this.dzongkhag; 
    this.registerObj.accountNo = this.accountNo;
    this.registerObj.status = 'Active';
    debugger
    this.apiService.updateUser(this.registerObj).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null) {
        alert("User Updated");
        this.modalService.close(this.myModal);
        
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["userlist"]));
      } else {
        alert("User update failed.");
      }
    });
  }

  approve(id, cid, dob, email, mobileNo, role, pinCode, name, dzoId) {
    this.registerObj.cid = cid;
    this.registerObj.dob = dob;
    this.registerObj.email = email;
    this.registerObj.mobileNo = mobileNo;
    this.registerObj.role = role;
    this.registerObj.createdOn = null;
    this.registerObj.pinCode = 0;
    this.registerObj.name = name;
    this.registerObj.id = id;
    this.registerObj.dzongkhagId = dzoId;

    this.apiService.approveUser(this.registerObj).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null) {
        alert("User Approved");
        //window.location="";
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["userlist"]));
      } else {
        alert("User update failed.");
      }
    });
  }

  reject(id, cid, dob, email, mobileNo, role, pinCode, name, dzoId) {
    this.registerObj.cid = cid;
    this.registerObj.dob = dob;
    this.registerObj.email = email;
    this.registerObj.mobileNo = mobileNo;
    this.registerObj.role = role;
    this.registerObj.createdOn = null;
    this.registerObj.pinCode = 0;
    this.registerObj.name = name;
    this.registerObj.id = id;
    this.registerObj.dzongkhagId = dzoId;
    this.registerObj.status = "Rejected";
    this.apiService.rejectUser(this.registerObj).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null) {
        alert("User Rejected");
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["userlist"]));
      } else {
        alert("User update failed.");
      }
    });
  }

  resetPassword(id){
    if (confirm("Are you sure to reset password for this user?")) {
      this.apiService.resetPassword(id).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["userlist"]));
        if (result != null) {
          alert("Password reset successful");
        } else {
          alert("Password reset unsuccessful");
        }
      });
    }
  }

  deactivateUser(id){
    if (confirm("Are you sure to deactivate this user?")) {
      this.apiService.deactivateUser(id).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["userlist"]));
        if (result != null) {
          alert("User has been deactivated");
        } else {
          alert("Could not deactivate user");
        }
      });
    }
  }

  activateUser(id){
    if (confirm("Are you sure to activate this user?")) {
      this.apiService.activateUser(id).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["userlist"]));
        if (result != null) {
          alert("User has been activated");
        } else {
          alert("Could not activate user");
        }
      });
    }
  }

  modalbackdrop() {
    this.modalService.close(this.myModal);
  }

  getName() {
    this.apiService.getNameFromAPI(this.cidNo).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));

      if (result != null) {
        this.name =
          response.citizenDetailsResponse.citizenDetail[0].firstName +
          " " +
          response.citizenDetailsResponse.citizenDetail[0].middleName +
          " " +
          response.citizenDetailsResponse.citizenDetail[0].lastName;
        this.dob = response.citizenDetailsResponse.citizenDetail[0].dob;
        this.mobileNo =
          response.citizenDetailsResponse.citizenDetail[0].mobileNumber;
      } else {
        //this.presentError();
      }
    });
  }
}
