import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';

export class Offence{
  offenceType: string;
  cidNo: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  mobileNo: string;
  schoolName: string;
  studentPhoneNo: string;
  guardian: string;
  licenseNo: string;
  vehicleNo: string;
  passportNo: string;
  location: string;
  file: string;
  offences: string;
  penalty: any;
  nationality: string;
  createdOn: string;
  paymentStatus: string;
  dzongkhagId: any;
  token: number;
  toDate: string;
  role: string;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  @ViewChild('myModal', { static: false }) myModal: Component
  
  offenceList: any;
  offenceObj: Offence;

  offenceType: string;
  cidNo: string = '';
  name: string = '';
  gender: string;
  dateOfBirth: string;
  mobileNo: string;
  schoolName: string;
  studentPhoneNo: string;
  guardian: string;
  licenseNo: string;
  vehicleNo: string;
  passportNo: string;
  location: string = '';
  file: string;
  offences: string = 'All';
  penalty: any;
  nationality: string;
  createdOn: string = 'null';
  dzongkhag: any = '0';
  paymentStatus: string = 'All';

  initialFlag: boolean = true;
  reportFlag: boolean = false;
  dzongkhagList: any;
  totalPenalty: any;
  toDate: string;
  fromDateValid: boolean = false;
  toDateValid: boolean = false;
  image: any;
  modalRef: any;

  constructor(private apiService: ApiService, 
              private router: Router,
              private modalService: ModalManager) {
    this.offenceObj = new Offence();
   }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.apiService.getAllOffenceMaster().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.offenceList = result;
       this.initialFlag = true;
       this.reportFlag = false;
      }else{
      }
     
    });
    this.getDzongkhag();

  }

  getDzongkhag(){
    this.apiService.getAllDzongkhag().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));

      if (result != null) {
        this.dzongkhagList = response;
      } else {
        //this.presentError();
      }

    })
  }

  generateReport(){
      this.offenceObj.cidNo=this.cidNo;
      this.offenceObj.name = this.name;
      this.offenceObj.dzongkhagId = this.dzongkhag;
      this.offenceObj.location = this.location;
      this.offenceObj.offences = this.offences;
      this.offenceObj.paymentStatus = this.paymentStatus;
      //this.offenceObj.createdOn = this.createdOn;
      this.apiService.getReport(this.offenceObj, this.createdOn).subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        
        if (result != null){
        this.offenceList = result;
        this.initialFlag = false;
        this.reportFlag = true;
        var tot = 0;
        for(var i =0; i<result.length; i++){
          tot += result[i].penalty;
          debugger
          if(result[i].enforcerUserId !=  null){
            if(result[i].enforcerUserId.role == 1){
              result[i].enforcerUserId.role = 'Admin'
            }
            if(result[i].enforcerUserId.role == 2){
              result[i].enforcerUserId.role = 'Enforcer'
            }
            if(result[i].enforcerUserId.role == 3){
              result[i].enforcerUserId.role = 'Public'
            }
            if(result[i].enforcerUserId.role == 4){
              result[i].enforcerUserId.role = 'Secondary Admin'
            }
          }
          else{
            
            result[i].enforcerUserId.role = 'None'
          }
        }
        this.totalPenalty = tot;
        //alert(this.totalPenalty)
        }else{
        }
      
      });
    
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['report']));
  }

  loadImage(img){
    this.image = img;
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

}
