import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-incidencereport',
  templateUrl: './incidencereport.component.html',
  styleUrls: ['./incidencereport.component.css']
})
export class IncidencereportComponent implements OnInit {
  initialFlag: boolean = true;
  reportFlag: boolean = false;
  incidenceList: any;
  createdOn: any;
  
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.initialFlag = true;
    this.reportFlag = false;
  }

  generateReport(){
    this.apiService.getIncidences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null){
       this.incidenceList = result;
       this.initialFlag = false;
       this.reportFlag = true;
      }else{
      }
     
    });
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['incidencereport']));
  }

}
