import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

export class News {
  title: string;
  details: string;
  image: string;
  type: string;
  createdOn: string;
  id: number;
}

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  title: string;
  details: string;
  image: string;
  type: string;
  createdOn: string;
  newsObj: News;

  constructor(
    private apiService: ApiService,
    private router: Router
  ) {
    this.newsObj = new News();
  }

  ngOnInit() {
    localStorage.setItem("login", "true")
  }

  addNews() {
    this.newsObj.title = this.title;
    this.newsObj.details = this.details;
    this.newsObj.image = this.image;
    this.newsObj.type = 'news';
    this.apiService.submitNews(this.newsObj).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      //console.log(result);

      if (result != null) {
        alert("News added")
        this.router.navigateByUrl('news')
      } else {
      }

    });
  }

  fileChangeEvent(fileInput: any) {
    // this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        // this.imageError =
        //     'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
      //     // this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          console.log(img_height, img_width);


          if (img_height > max_height && img_width > max_width) {
            // this.imageError =
            //     'Maximum dimentions allowed ' +
            //     max_height +
            //     '*' +
            //     max_width +
            //     'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.image = imgBase64Path;
            console.log(this.image);

            // this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

}
