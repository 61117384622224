import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { ApiService } from '../api.service';

export class Offence{
  offenceType: string;
  cidNo: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  mobileNo: string;
  schoolName: string;
  studentPhoneNo: string;
  guardian: string;
  licenseNo: string;
  vehicleNo: string;
  passportNo: string;
  location: string;
  file: string;
  offences: string;
  penalty: any;
  nationality: string;
  id: any;
  token: any;
  enforcerUserId: string;
}

@Component({
  selector: 'app-offencelist',
  templateUrl: './offencelist.component.html',
  styleUrls: ['./offencelist.component.css']
})
export class OffencelistComponent implements OnInit {

  @ViewChild('myModal', { static: false }) myModal: Component
  @ViewChild('editModal', { static: false }) editModal: Component
  
  offenceList: any;
  offenceListOriginal: any;
  addOffenceModal: boolean = false;

  offenceType: string;
  cidNo: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  mobileNo: string;
  schoolName: string;
  studentPhoneNo: string;
  guardian: string;
  licenseNo: string;
  vehicleNo: string;
  passportNo: string;
  location: string;
  file: string;
  offences: string;
  penalty: any;
  nationality: string;

  individual: boolean = false;
  student: boolean = false;
  vehicle: boolean = false;
  business: boolean = false;
  penaltyFlag: boolean = false;
  nonbhutanese: boolean = false;
  id: any;
  token: any;
  enforcerUserId: string;

  offenceObj: Offence;
  modalRef: any;

  constructor(private apiService: ApiService, private router: Router, private modalService: ModalManager) {
    this.offenceObj = new Offence();
   }

  ngOnInit() {
    localStorage.setItem("login", "true");
    this.enforcerUserId = localStorage.getItem("enforceruid");
    
    this.apiService.getAllOffences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null){
       this.offenceList = result;
       this.offenceListOriginal = result;
      }else{
      }
     
    });

  }

  editFlag: boolean = false;
  editOffence(cid){ //cid here is token
    this.getOffenceMaster();
    this.apiService.getOffenceByToken(cid).subscribe((response) => {
      
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.offenceList = result;
       this.editFlag = true;
       this.offenceType = result[0].offenceType;
       this.nationality = result[0].nationality;
       this.cidNo = result[0].cidNo;
       this.name = result[0].name;
       this.gender = result[0].gender;
       this.dateOfBirth = result[0].dateOfBirth;
       this.mobileNo = result[0].mobileNo;
       this.location = result[0].location;
       this.offences = result[0].offences;
       this.penalty = result[0].penalty;
       this.id = result[0].id;
       this.token = result[0].token;
       this.showForm();
       this.showNationality();
       this.showPenalty()
      }else{
      }
     
    });

    //show modal
    this.modalRef = this.modalService.open(this.editModal, {
      size: "lg",
      modalClass: 'editmodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
    
  }

  addOffence(){
    this.addOffenceModal = true;
    this.editFlag = false
    
    this.getOffenceMaster();
  }

  offenceMaster: any;
  getOffenceMaster(){
    this.apiService.getAllOffenceMaster().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.offenceMaster = result;
      }else{
      }
     
    });
  }

  getName(){
    this.apiService.getNameFromAPI(this.cidNo).subscribe((response) => {
      console.log('data is '+JSON.stringify(response));
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        var middleName = "";
        var lastName = "";
        if(response.citizenDetailsResponse.citizenDetail[0].middleName != null){
          middleName = response.citizenDetailsResponse.citizenDetail[0].middleName;
        }
        if(response.citizenDetailsResponse.citizenDetail[0].lastName != null){
          lastName = response.citizenDetailsResponse.citizenDetail[0].lastName;
        }
        this.name = response.citizenDetailsResponse.citizenDetail[0].firstName +
                    " " + middleName +
                    " " + lastName ;
        this.dateOfBirth = response.citizenDetailsResponse.citizenDetail[0].dob;
        if(response.citizenDetailsResponse.citizenDetail[0].gender == 'M'){
          this.gender = "Male";
        }
        else{
          this.gender = "Female";
        }
        this.mobileNo = response.citizenDetailsResponse.citizenDetail[0].mobileNumber;
      }else{
        //this.presentError();
      }
     
    })
  }

  getVehicleDetails(){
    this.apiService.getVehicleDetails(this.vehicleNo).subscribe((response) => {
      //console.log('data is '+JSON.stringify(response));
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        this.name = response.vehiclenumberdetails.vehiclenumberdetail[0].ownerName;
        this.dateOfBirth = response.vehiclenumberdetails.vehiclenumberdetail[0].dateOfBirth;
        this.licenseNo = response.vehiclenumberdetails.vehiclenumberdetail[0].drivingLicenseNo;
        this.mobileNo = response.vehiclenumberdetails.vehiclenumberdetail[0].phoneNumber;
        this.location = response.vehiclenumberdetails.vehiclenumberdetail[0].gewogName + 
        " " + response.vehiclenumberdetails.vehiclenumberdetail[0].dzongkhagName;
      }else{
        //this.presentError();
      }
     
    })
  }

  getLicenseDetails(){
    this.apiService.getLicenseDetails(this.licenseNo).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
        this.name = response.licenses.license[0].licenseName;
        this.passportNo = response.licenses.license[0].cidOrRegNo;
         this.mobileNo = response.licenses.license[0].mobileNumber;
         this.location = response.licenses.license[0].location;
      }else{
        //this.presentError();
      }
     
    })
  }

  getPassportDetails(){
    this.apiService.getPassportDetails(this.passportNo).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
         this.name = response.immigrantdetails.immigrantdetail[0].FirstName + " "+ response.immigrantdetails.immigrantdetail[0].MiddleName + response.immigrantdetails.immigrantdetail[0].LastName;
         this.dateOfBirth = response.immigrantdetails.immigrantdetail[0].DOB;
        // this.mobileNo = response.licenses.license[0].mobileNumber;
         this.gender = response.immigrantdetails.immigrantdetail[0].Gender;
      }else{
        //this.presentError();
      }
     
    })
  }

  register(type){
    this.offenceObj.offenceType = this.offenceType;
    this.offenceObj.cidNo = this.cidNo;
    this.offenceObj.dateOfBirth = this.dateOfBirth;
    this.offenceObj.file = this.file;
    this.offenceObj.gender = this.gender;
    this.offenceObj.guardian = this.guardian;
    this.offenceObj.licenseNo = this.licenseNo;
    this.offenceObj.location = this.location;
    this.offenceObj.mobileNo = this.mobileNo;
    this.offenceObj.name = this.name;
    this.offenceObj.offences = this.offences;
    this.offenceObj.passportNo = this.passportNo;
    this.offenceObj.penalty = this.penalty;
    this.offenceObj.schoolName = this.schoolName;
    this.offenceObj.studentPhoneNo = this.studentPhoneNo;
    this.offenceObj.vehicleNo = this.vehicleNo;
    this.offenceObj.nationality = this.nationality;
    this.offenceObj.token = this.token;
    this.offenceObj.enforcerUserId = this.enforcerUserId
    
   
    if(type == 'update'){
      this.offenceObj.id = this.id;
      this.apiService.updateOffence(this.offenceObj).subscribe((response) => {
        
         var result = JSON.parse(JSON.stringify(response));
         
         if (result != null){
          
             this.presentAlert("Offence updated");
             this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
             this.router.navigate(['offencelist']));
             window.location.reload();
             
         }else{
           //this.presentError();
         }
        
       })
    }else{
      this.apiService.submitOffence(this.offenceObj).subscribe((response) => {
        // console.log('data is '+JSON.stringify(response));
         var result = JSON.parse(JSON.stringify(response));         
         if (result != null){
          
             this.presentAlert("You have registered offence successfully.");
           
             
         }else{
           //this.presentError();
         }
        
       })
    }

    
  }

  async presentAlert(msg) {
    alert(msg);
  }

  nationalityFlag: boolean = false;
  showForm(){
    if(this.offenceType == "individual"){
      this.nationalityFlag = true;
      this.student = false;
      this.business = false;
      this.vehicle = false;
    }
    else if(this.offenceType == "student"){
      this.student = true;
      this.individual = false;
      this.business = false;
      this.vehicle = false;
    }
    else if(this.offenceType == "vehicle"){
      this.vehicle = true;
      this.student = false;
      this.individual = false;
      this.business = false;
    }
    else{
      this.business = true;
      this.vehicle = false;
      this.student = false;
      this.individual = false;
    }
  }
  
  showNationality(){
    if(this.nationality == 'Bhutanese'){
      this.individual = true;
      this.nonbhutanese = false;
    }
    else{
      this.nonbhutanese = true;
      this.individual = false;
    }
  }

  showPenalty(){
    console.log(this.offences);
    
    this.penalty=this.offenceList[this.offences].penalty;
    this.penaltyFlag = true;
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['dashboard']));
  }

  delUsers(id){
    if (confirm("Are you sure to delete ")) {
      this.apiService.deleteOffenceById(id).subscribe(
        (response) => {
          alert(response);  // Alert the response from the server
          this.router.navigateByUrl('/offencelist', { skipLocationChange: true }).then(() => {
            // Use skipLocationChange to navigate without adding a new entry in the browser history
            this.router.navigate(['/offencelist']);
          });
        },
        (error) => {
          alert('Error deleting offence: ' + error.error);
        }
      );
    }
  }

  fileChangeEvent(fileInput: any) {
    // this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        // this.imageError =
        //     'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
      //     // this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          console.log(img_height, img_width);


          if (img_height > max_height && img_width > max_width) {
            // this.imageError =
            //     'Maximum dimentions allowed ' +
            //     max_height +
            //     '*' +
            //     max_width +
            //     'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.file = imgBase64Path;

            // this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  loadImage(img){
    this.file = img;
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

}
