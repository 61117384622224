import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-paymentreport',
  templateUrl: './paymentreport.component.html',
  styleUrls: ['./paymentreport.component.css']
})
export class PaymentreportComponent implements OnInit {
  initialFlag: boolean = true;
  reportFlag: boolean = false;
  offenceList: any;
  createdOn: any;
  totalPenalty: any;
  
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.initialFlag = true;
    this.reportFlag = false;
  }

  generateReport(){
    this.apiService.getAllOffences().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null){
       this.offenceList = result;
       this.initialFlag = false;
       this.reportFlag = true;
       var tot = 0;
       for(var i =0; i<result.length; i++){
        tot += result[i].penalty;
       }
       this.totalPenalty = tot;
      }else{
      }
     
    });
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['paymentreport']));
  }


}
