import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Offence, ReportComponent } from '../report/report.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  initialFlag: boolean = true;
  reportFlag: boolean = false;
  offenceList: any;
  sortList: any;
  createdOn: any = 'null';
  totalPenalty: any;
  toDate: string;
  sort: any = 0;
  sortedList: any = [];
  
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    localStorage.setItem("login", "true")
    this.initialFlag = true;
    this.reportFlag = false;
  }
  offenceObj: any;
  fromDateValid: boolean = false;
  toDateValid: boolean = false;

  generateReport(){
    
      this.offenceObj = new Offence();
      this.offenceObj.cidNo = "";
      this.offenceObj.name = "";
      this.offenceObj.dzongkhagId = 0;
      this.offenceObj.location = "";
      this.offenceObj.offences = "All";
      this.offenceObj.paymentStatus = "All";
      //this.offenceObj.createdOn = this.createdOn;
    
      this.apiService.getReport(this.offenceObj, this.createdOn).subscribe((response) => {
      //this.apiService.getAllOffences().subscribe((response) => {
        var result = JSON.parse(JSON.stringify(response));
        
        if (result != null){
        this.offenceList = result;
        this.sortList = result; 
        this.initialFlag = false;
        this.reportFlag = true;
        var tot = 0;
        for(var i =0; i<result.length; i++){
          // const date = new Date(result[i].createdOn);
          // date.setDate(date.getDate() + 20);
          // this.offenceList[i].createdOn = 
          tot += result[i].penalty;
        }
        this.totalPenalty = tot;
        }else{
        }
      
      });
    
    
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['payment']));
  }

  sortBy() {
    debugger
    this.sortedList = [];
    for (var i = 0; i < this.sortList.length; i++) {
      if (this.sort == this.sortList[i].paymentStatus) {
        this.sortedList.push(this.sortList[i]);
      }
    }
    this.offenceList = this.sortedList;

    
  }

  

}
