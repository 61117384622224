import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
export class Offence{
  offenceType: string;
  cidNo: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  mobileNo: string;
  schoolName: string;
  studentPhoneNo: string;
  guardian: string;
  licenseNo: string;
  vehicleNo: string;
  passportNo: string;
  location: string;
  file: string;
  offences: string;
  penalty: any;
  nationality: string;
  createdOn: string;
  paymentStatus: string;
  dzongkhagId: any;
  token: number;
  enforcerUserId: string;

}

@Component({
  selector: 'app-offencereport',
  templateUrl: './offencereport.component.html',
  styleUrls: ['./offencereport.component.css']
})
export class OffencereportComponent implements OnInit {
  offenceList: any;
  offenceObj: Offence;

  offenceType: string;
  cidNo: string = '';
  name: string = '';
  gender: string;
  dateOfBirth: string;
  mobileNo: string;
  schoolName: string;
  studentPhoneNo: string;
  guardian: string;
  licenseNo: string;
  vehicleNo: string;
  passportNo: string;
  location: string = '';
  file: string;
  offences: string = 'All';
  penalty: any;
  nationality: string;
  createdOn: string = 'null';
  dzongkhag: any = '0';
  paymentStatus: string = 'All';

  initialFlag: boolean = true;
  reportFlag: boolean = false;
  dzongkhagList: any;
  totalPenalty: any;
  enforcerUserId: string;
  subAdminDzongkhag: any;
  role: any;

  constructor(private apiService: ApiService, 
              private router: Router) {
    this.offenceObj = new Offence();
   }

  ngOnInit() {
    this.enforcerUserId = localStorage.getItem('enforceruid');
    this.subAdminDzongkhag = localStorage.getItem('dzongkhagId')
    this.role = localStorage.getItem('userrole')

    localStorage.setItem("login", "true")
    this.apiService.getAllOffenceMaster().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      
      if (result != null){
       this.offenceList = result;
       this.initialFlag = true;
       this.reportFlag = false;
      }else{
      }
     
    });
    this.getDzongkhag();
  }

  ngAfterViewInit() {
    //We loading the player script on after view is loaded
    if(this.role == 4) //role 4 is sub admin
    {
      this.dzongkhag = this.subAdminDzongkhag;
    }
    
  }

  getDzongkhag(){
    this.apiService.getAllDzongkhag().subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));

      if (result != null) {
        this.dzongkhagList = response;
      } else {
        //this.presentError();
      }

    })
  }

  generateReport(){
    this.offenceObj.cidNo=this.cidNo;
    this.offenceObj.name = this.name;
    this.offenceObj.dzongkhagId = this.dzongkhag;
    this.offenceObj.location = this.location;
    this.offenceObj.offences = this.offences;
    this.offenceObj.paymentStatus = this.paymentStatus;
    //this.offenceObj.createdOn = this.createdOn;
    this.offenceObj.enforcerUserId = this.enforcerUserId;
    
    this.apiService.getReport(this.offenceObj, this.createdOn).subscribe((response) => {
      var result = JSON.parse(JSON.stringify(response));
      if (result != null){
       this.offenceList = result;
       this.initialFlag = false;
       this.reportFlag = true;
       var tot = 0;
       for(var i =0; i<result.length; i++){
        tot += result[i].penalty;
       }
       this.totalPenalty = tot;
       //alert(this.totalPenalty)
      }else{
      }
     
    });
  }

  back(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['enforcer']));
  }

}
